const feet: Foot[] = [
    {
        id: 'H100-3#size#',
        minWeight: 40,
        maxWeight: 60,
        minSize: 23,
        maxSize: 24,
        height: [
            [23, 104],
            [24, 102],
        ],
        description:
            'Optimal inversion/eversion dynamic function for surface accommodation',
        color: 'Green',
    },
    {
        id: 'H100-4#size#',
        minWeight: 40,
        maxWeight: 80,
        minSize: 23,
        maxSize: 29,
        height: [
            [23, 105],
            [24, 102],
            [25, 112],
            [26, 115],
            [27, 116],
            [28, 117],
            [29, 118],
        ],
        description:
            'Optimal inversion/eversion dynamic function for surface accommodation',
        color: 'Green',
    },
    {
        id: 'H100-5#size#',
        minWeight: 40,
        maxWeight: 100,
        minSize: 23,
        maxSize: 29,
        height: [
            [23, 106],
            [24, 103],
            [25, 112],
            [26, 116],
            [27, 116],
            [28, 119],
            [29, 120],
        ],
        description:
            'Increased resistance for higher energy return while still maintaining exceptional side-to-side flexibility',
        color: 'Yellow',
    },
    {
        id: 'H100-6#size#',
        minWeight: 40,
        maxWeight: 125,
        minSize: 25,
        maxSize: 29,
        height: [
            [25, 114],
            [26, 116],
            [27, 117],
            [28, 120],
            [29, 121],
        ],
        description:
            'Maximum rigidity, loss of some medial/lateral ground compliance',
        color: 'Grey',
    },
    {
        id: 'H100-7#size#',
        minWeight: 40,
        maxWeight: 150,
        minSize: 26,
        maxSize: 29,
        height: [
            [26, 115],
            [27, 116],
            [28, 121],
            [29, 121],
        ],
        description: 'Exaggerated stiffness',
        color: 'Grey',
    },
];

export { feet };
